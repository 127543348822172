import * as R from 'ramda';
import React from 'react';
import { L } from '@poly/site-book';
import { history } from '@poly/client-routing';
import { ESFM_INFO } from '@poly/constants';
import { ApolloProvider } from '@apollo/client';
import { func, node, oneOfType } from 'prop-types';
import {
  useUserLogout,
  handleApplicationMaintenance,
} from '@poly/client-utils';
import { createHandleApolloErrorAtSiteUI } from '@poly/site-ui';
import { createApolloClient } from '@poly/apollo-client';

import { routes } from '../routes.js';

const authRoutesList = [
  routes.login,
  routes.forgotPassword,
  routes.changePassword,
];

const shouldLogout = R.compose(
  R.complement(R.includes(R.__, authRoutesList)),
  R.when(R.includes('set-password'), R.always(routes.changePassword)),
);

function ClientMarkupRulesError() {
  const displayPhoneNumber = R.replace(/-/g, '.', ESFM_INFO.telephone);
  const hrefPhoneNumber = R.replace(/-/g, '', ESFM_INFO.telephone);

  return (
    <>
      An error occurred while creating the request. Please contact support for
      assistance
      <L href={`tel:${hrefPhoneNumber}`}>{displayPhoneNumber}</L>
    </>
  );
}

export function ApolloClientProvider({ children }) {
  const logOutUser = useUserLogout();

  const handleLogout = () =>
    logOutUser({ localStorage, loginRoute: routes.login });

  const onTokenHasExpired = () => {
    if (shouldLogout(history.location.pathname)) {
      handleLogout();
    }
  };

  const apolloClient = createApolloClient(
    onTokenHasExpired,
    createHandleApolloErrorAtSiteUI(handleLogout, ClientMarkupRulesError),
    handleApplicationMaintenance,
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ApolloClientProvider.propTypes = {
  children: oneOfType([node, func]).isRequired,
};
